<template>
  <div class="main-container">
    <div class="inner-container">
      <div class="video">
        <img :src="photo" />
      </div>
      <div class="meetOurTeam-text-container">
        <div class="meetOurTeam-text-inner-container">
          <div class="teamSection-heading">
            {{ $t("teamSection.heading") }}
          </div>
          <div class="teamSection-text">
            {{ $t("teamSection.subtitle.part1") }}<br />
          </div>
        </div>
        <div class="button-container">
          <button @click="goToAboutUs" type="button" class="learnMore-button">
            {{ $t("teamSection.learnMore") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import msosLogo from "@/assets/images/videoImg.png";
import teamPhoto from "@/assets/images/activitiesImgg.png";

import aboutUs from "@/sites/AboutUs.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/about-us",
    component: aboutUs,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default {
  name: "NavBar",
  data() {
    return {
      msosLogo,
      photo: teamPhoto,
    };
  },
  methods: {
    goToAboutUs() {
      this.$router.push("/about-us");
    },
  },
  router,
};
</script>

<style scoped>
.rl {
  text-decoration: none;
}

.button-container {
  display: flex;
}
.main-container {
  width: 100%;
  height: 100%;
  padding-top: 128px;
  padding-bottom: 70px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.inner-container {
  display: flex;
  width: 90%;
  justify-content: space-around;
  gap: 30px;
}

.video {
  width: 90%;
  position: relative;
}

.meetOurTeam-text-container {
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 56px;
  display: inline-flex;
}

.meetOurTeam-text-inner-container {
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 20px;
  display: flex;
}

img {
  border-radius: 24px;
}

.teamSection-heading {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
}

.teamSection-text {
  width: 80%;
  color: #667085;
  text-align: start;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.learnMore-button {
  outline: none;
  cursor: pointer;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #d0d5dd solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  color: #344054;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
  align-self: flex-start;
  text-decoration: none;
}

@media only screen and (max-width: 1024px) {
  .meetOurTeam-text-container {
    width: 70%;
  }

  .meetOurTeam-text-inner-container,
  .teamSection-text {
    width: 100%;
    font-size: 17px;
  }

  .video img {
    width: 90%;
  }

  .video {
    width: 70%;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
  }

  .meetOurTeam-text-container {
    width: 47%;
  }

  .inner-container {
    width: 90%;
    justify-content: space-around;
  }

  .learnMore-button {
    font-size: 15px;
    padding: 10px 20px 10px 20px;
  }
}
@media only screen and (max-width: 768px) {
  .inner-container {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
  }

  .meetOurTeam-text-container {
    width: 85%;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }
  .meetOurTeam-text-inner-container {
    align-items: center;
  }

  .teamSection-text {
    text-align: center;
  }

  .learnMore-button {
    font-size: 13px;
    padding: 7px 17px 7px 17px;
  }

  .video {
    width: 90%;
  }

  .video img {
    width: 95%;
  }
}
@media only screen and (max-width: 480px) {
  .inner-container {
    width: 98%;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
  }

  .video img {
    width: 95px;
  }

  .video {
    width: 90%;
    display: inline-flex;
    align-items: start;
    justify-content: space-around;
  }

  .video img {
    width: 98%;
  }

  .meetOurTeam-text-container {
    gap: 30px;
    align-items: center;
  }

  .meetOurTeam-text-inner-container {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .teamSection-heading {
    width: 100%;
    text-align: center !important;
    font-size: 24px;
    align-items: center;
  }

  .teamSection-text {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
  }

  .learnMore-button {
    font-size: 10px;
    padding: 5px 15px 5px 15px;
  }
}
</style>
