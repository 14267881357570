<template>
  <img :src="msosLogo" />
  <h1>{{ $t("underConstruction.heading") }}</h1>
  <!-- <p>{{ $t("underConstruction.subtitle") }}</p> -->
</template>

<script>
import msosLogo from "@/assets/images/msos_logo.png";

export default {
  name: "UnderConstruction",
  components: {},

  data() {
    return {
      msosLogo,
    };
  },
};
</script>

<style scoped>
body {
  margin: 0;
}

img {
  margin-top: 150px;
  width: 100px;
  height: 100px;
}
</style>
