<template>
  <div class="main-container">
    <div class="content-container">
      <div class="form-container">
        <div class="form-header-container">
          <h1>{{ $t("Contact.heading") }}</h1>
          <h3>{{ $t("Contact.subheading") }}</h3>
        </div>
        <form class="form-input-container">
          <div class="form-name">
            <div class="field">
              <label class="input-field-label">{{
                $t("Contact.firstName")
              }}</label
              ><br />
              <input class="input-field" type="text" v-model="name" />
            </div>
            <div class="field">
              <label class="input-field-label">{{
                $t("Contact.lastName")
              }}</label
              ><br />
              <input class="input-field" type="text" v-model="surname" />
            </div>
          </div>
          <div class="field">
            <label class="input-field-label">{{ $t("Contact.email") }}</label
            ><br />
            <input
              class="input-field"
              type="text"
              v-model="email"
              @change="validateEmail()"
            />
          </div>
          <div class="valid-email" v-if="!validEmail">
            {{ $t("Contact.validEmail") }}
          </div>
          <div class="field">
            <label class="input-field-label">{{ $t("Contact.message") }}</label
            ><br />
            <textarea
              class="input-field-message"
              type="text"
              v-model="message"
            ></textarea>
          </div>
          <button
            class="button"
            :disabled="!validEmail || !email || !name || !message"
            @click="sendEmail()"
          >
            {{ $t("Contact.sendMessage") }}
          </button>
        </form>
      </div>
      <div class="image-container">
        <img :src="contactsImage" />
      </div>
    </div>
  </div>
</template>

<script>
// import Form from "@/components/Form.vue";
import contactsImage from "@/assets/images/4. Contact page/msos-contact page.png";
import axios from "axios";

export default {
  name: "ContactUs",
  components: {
    // Form,
  },
  data() {
    return {
      contactsImage,
      name: "",
      surname: "",
      email: "",
      message: "",
      validEmail: false,
    };
  },
  methods: {
    sendEmail() {
      // this.valid = this.name != null || this.email != null || this.message != null
      axios
        .post("https://api.msosorg.com/send-email/", {
          name: `${this.name} ${this.surname}`,
          email: this.email,
          message: this.message,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validateEmail() {
      if (
        this.email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-container {
  display: flex;
  width: 1280px;
  padding: 48px;
  flex-direction: row;
  justify-content: space-around;
}

.form-header-container h1 {
  color: #101828;
  text-align: start;
}

.form-header-container h3 {
  color: #667085;
  text-align: start;
}

.valid-email {
  color: #ff9090;
  padding: 8px;
}

.button {
  width: 101%;
  background-color: #2d738c;
  border: 1px #2d738c solid;
  height: 48px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  text-align: center;
}

button:disabled {
  background: gray;
}

.field {
  padding-top: 16px;
  padding-bottom: 16px;
}

label {
  text-align: start;
}

.input-field-label {
  width: 100%;
  display: inline-flex;
  font-size: 14px;
  padding: 8px;
  color: #344054;
}

.input-field {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  background: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #d0d5dd solid;
}

.input-field-message {
  width: 100%;
  background: white;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #d0d5dd solid;
  resize: none;
}

.form-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  width: 70%;
}

body {
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .image-container img {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .image-container {
    display: none;
  }

  .form-name {
    flex-direction: column;
  }
}
</style>
