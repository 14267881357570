<template>
  <div class="headerAboutUsSection">
    <div class="header-container">
      <div class="header-content">
        <div class="headingAndSupportingText-container">
          <div class="aboutUs-AboutMSOS-container">
            <p class="aboutUs-text">{{ $t("aboutUsHeader.aboutUs") }}</p>
            <p class="aboutMSOS-text">{{ $t("aboutUsHeader.aboutMSOS") }}</p>
          </div>
          <p class="subtitle">
            {{ $t("aboutUsHeader.subtitle") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import msosLogo from "@/assets/images/msos_logo.png";

export default {
  name: "AboutUsHeader",
  data() {
    return {
      msosLogo,
    };
  },
};
</script>

<style scoped>
.headerAboutUsSection {
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}

.header-container {
  height: 150px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: flex;
}

.header-content {
  align-self: stretch;
  height: 150px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  display: flex;
}

.headingAndSupportingText-container {
  width: 100%;
  height: 150px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: inline-flex;
}

.aboutUs-AboutMSOS-container {
  width: 100%;
  height: 96px;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.aboutUs-text {
  margin-bottom: 0px;
  align-self: stretch;
  text-align: center;
  color: #fa7850;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.aboutMSOS-text {
  margin-top: 0px;
  align-self: stretch;
  text-align: center;
  color: #101828;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  word-wrap: break-word;
}

.subtitle {
  width: 90%;
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .headerAboutUsSection {
    padding-top: 64px;
    padding-bottom: 64px;
    gap: 48px;
  }
}

@media only screen and (max-width: 768px) {

}

@media only screen and (max-width: 480px) {
  .headerAboutUsSection {
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 24px;
  }
  .aboutMSOS-text {
    font-size: 36px;
  }
  .subtitle {
    line-height: 25px;
  }
}
</style>
