<template>
  <div class="home-container">
    <div class="blurryPanel-container">
      <img class="blurryPanel" :src="blurryPanel" />
      <div class="text-overlay">
        <div class="heading1-container">
          <div class="heading1-inner">
            <div class="heading1">
              {{ $t("homePage.heading1") }}
            </div>
          </div>
        </div>
        <div class="heading2">
          {{ $t("homePage.heading2") }}
        </div>
        <div class="heading3">
          {{ $t("homePage.heading3.part1") }}
          <br />{{ $t("homePage.heading3.part2") }}
        </div>
        <button
          @click="goToFormSection" class="becomeAMemberButton">
          <div class="becomeAMemberButton-inner">
            {{ $t("homePage.becomeAMember") }}
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="homeImg-container">
    <img class="homePageImg" :src="homePageImg" />
  </div>
</template>

<script>
import homePageImg from "@/assets/images/homepageImg.png";
import blurryPanel from "@/assets/images/blurryPanel.png";

export default {
  name: "NavBar",
  data() {
    return {
      homePageImg,
      blurryPanel,
    };
  },
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
    goToFormSection() {
      this.$router.push('/form');
    }
  },
};
</script>

<style scoped>
.text-overlay {
  position: absolute;
  z-index: 2;
  top: 120px;
  left: 104px;
  text-align: left;
}
.home-container {
  display: flex;
  position: absolute;
}

.blurryPanel-container {
  display: flex;
  z-index: 1;
  top: 0;
}

.homeImg-container {
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  right: 0;
}

.homePageImg {
  height: 801px;
}

.heading1-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.heading1-inner {
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
}

.heading1 {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.heading2 {
  color: white;
  font-size: 55px;
  font-weight: 700;
  margin-top: 70px;
  line-height: 72px;
  word-wrap: break-word;
}

.heading3 {
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 400;
  margin-top: 24px;
  line-height: 30px;
  word-wrap: break-word;
}

.becomeAMemberButton {
  width: 38%;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #fa7850;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 100px;
  display: inline-flex;
  border: none;
  cursor: pointer;
}

.becomeAMemberButton-inner {
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .homeImg-container {
    display: none;
  }

  .home-container {
    display: flex;
    position: relative;
  }

  .blurryPanel-container {
    width: 100%;
  }

  .blurryPanel-container img {
    width: 100%;
  }

  .text-overlay {
    width: 70%;
    top: 80px;
  }

  .heading2 {
    font-size: 45px;
    line-height: 60px;
  }

  .becomeAMemberButton {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .text-overlay {
    width: 80%;
    top: 60px;
    left: 60px;
  }

  .heading2 {
    font-size: 37px;
    line-height: 45px;
  }

  .heading3 {
    font-size: 17px;
    line-height: 25px;
  }
  .becomeAMemberButton {
    width: 50%;
    padding-left: 25px;
    padding-right: 25px;
  }

  .becomeAMemberButton-inner {
    font-size: 15px;
  }
}

@media only screen and (max-width: 570px) {
  .text-overlay {
    width: 80%;
    top: 30px;
    left: 40px;
  }

  .heading1-container {
    margin-top: 15px;
  }

  .heading2 {
    font-size: 30px;
    line-height: 35px;
  }

  .heading3 {
    font-size: 14px;
    line-height: 20px;
  }

  .becomeAMemberButton {
    width: 50%;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 70px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .text-overlay {
    width: 87%;
    top: 30px;
    left: 25px;
  }

  .heading1-container {
    margin-top: 0;
  }

  .heading1 {
    font-size: 13px;
    line-height: 17px;
  }

  .heading2 {
    font-size: 25px;
    line-height: 27px;
    margin-top: 45px;
  }

  .heading3 {
    font-size: 13px;
    line-height: 20px;
  }

  .becomeAMemberButton {
    width: 60%;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .becomeAMemberButton-inner {
    font-size: 14px;
  }
}
@media only screen and (max-width: 355px) {
  .text-overlay {
    width: 85%;
    top: 30px;
    left: 20px;
  }

  .heading1-container {
    margin-top: 0;
  }

  .heading1 {
    font-size: 12px;
    line-height: 15px;
  }

  .heading2 {
    font-size: 20px;
    line-height: 23px;
    margin-top: 30px;
  }

  .heading3 {
    font-size: 12px;
    line-height: 18px;
  }

  .becomeAMemberButton {
    width: 70%;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .becomeAMemberButton-inner {
    font-size: 14px;
  }
}
</style>
