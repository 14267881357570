<template>
  <div class="main-container">
    <div class="container">
      <div class="content">
        <div class="headingAndSupportText-container">
          <p class="heading">{{ $t("ourValues.heading") }}</p>
          <p class="subheading">{{ $t("ourValues.subtitle") }}</p>
        </div>
      </div>
    </div>

    <div class="FAQcards-container">
      <!-- cards : first row -->
      <div class="miniCards-container">
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="users_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value1") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc1") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="heart_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value2") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc2") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="tradingUp_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value3") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc3") }}
            </p>
          </div>
        </div>
      </div>

      <!--cards: 2 row -->
      <div class="miniCards-container">
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="smile_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value4") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc4") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="flag_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value5") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc5") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="zap_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value6") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc6") }}
            </p>
          </div>
        </div>
      </div>

      <!--cards: 3 row -->
      <div class="miniCards-container dopolnitelni-postavki">
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="smile_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value7") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc7") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="flag_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("ourValues.value8") }}</p>
            <p class="card-description">
              {{ $t("ourValues.desc8") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import users_icon from "@/assets/images/users.svg";
import heart_icon from "@/assets/images/faq_icon.svg";
import tradingUp_icon from "@/assets/images/prideInWhatWeDo.svg";
import smile_icon from "@/assets/images/smile.svg";
import flag_icon from "@/assets/images/flag.svg";
import zap_icon from "@/assets/images/zap.svg";
import question_icon from "@/assets/images/zap.svg";

export default {
  name: "NavBar",
  data() {
    return {
      question_icon,
      users_icon,
      heart_icon,
      tradingUp_icon,
      smile_icon,
      flag_icon,
      zap_icon,
    };
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background: #f9fafb;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}

.content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  display: flex;
}
.headingAndSupportText-container {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.heading {
  /* margin-bottom: 0px; */
  text-align: center;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
}

.subheading {
  margin-top: 0px;
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.FAQcards-container {
  width: 90%;
  /* background-color: yellow; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* gap: 15.px; */
  /* margin-top: 64px; */
  display: inline-flex;
}

.miniCards-container {
  /* background-color: aquamarine; */
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
}

.card-container {
  width: 40%;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;
}

.icon-container {
  padding: 12px;
  background: rgba(39, 77, 90, 0.08);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.img-icon {
  width: 24px;
  height: 24px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.miniCard-content-container {
  align-self: stretch;
  /* height: 110px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.card-heading {
  /* margin-top: 0px; */
  margin-bottom: 0px;
  align-self: stretch;
  text-align: center;
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  /* line-height: 30px; */
  word-wrap: break-word;
}

.card-description {
  /* margin-top: 0px; */
  align-self: stretch;
  color: #667085;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}
@media only screen and (max-width: 1024px) {
  .FAQcards-container {
    gap: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .FAQcards-container {
    gap: 40px;
    justify-content: center;
    align-items: center;
  }

  .miniCards-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-container {
    width: 80%;
  }
}

@media only screen and (max-width: 480px) {
  .card-container {
    gap: 15px;
  }

  .heading {
    font-size: 30px;
  }

  .subheading {
    font-size: 18px;
  }
}

.dopolnitelni-postavki {
  margin-top: 25px;
  width: 60%;
}
</style>
