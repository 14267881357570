<template>
  <div class="main-container">
    <div class="container">
      <div class="content">
        <div class="headingAndSupportText-container">
          <p class="heading">{{ $t("latestNews.heading") }}</p>
          <p class="subheading">{{ $t("latestNews.subtitle") }}</p>
        </div>
      </div>
    </div>
    <div class="threeBlogPosts-container">
      <div class="blogPost-container">
        <img @click="goToVasilBlogTemplate" class="blogImg" :src="blogImg" />
        <div class="blogPostInfo-container">
          <p class="authorAndDate">{{ $t("latestNews.authorAndDate.1") }}</p>
          <p @click="goToVasilBlogTemplate" class="blogPostHeading">
            {{ $t("latestNews.blogPostHeading.1") }}
          </p>
          <p class="blogPostDescription">
            {{ $t("latestNews.blogPostDescription.1") }}
          </p>
        </div>
      </div>
      <!-- <div class="blogPost-container">
        <img class="blogImg" :src="blogImg" />
        <div class="blogPostInfo-container">
          <p class="authorAndDate">{{ $t("latestNews.authorAndDate.2") }}</p>
          <p class="blogPostHeading">
            {{ $t("latestNews.blogPostHeading.2") }}
          </p>
          <p class="blogPostDescription">
            {{ $t("latestNews.blogPostDescription.2") }}
          </p>
        </div>
      </div> -->
      <!-- <div class="blogPost-container">
        <img class="blogImg" :src="blogImg" />
        <div class="blogPostInfo-container">
          <p class="authorAndDate">{{ $t("latestNews.authorAndDate.3") }}</p>
          <p class="blogPostHeading">
            {{ $t("latestNews.blogPostHeading.3") }}
          </p>
          <p class="blogPostDescription">
            {{ $t("latestNews.blogPostDescription.3") }}
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import blogImg from "@/assets/images/maribor.jpg";

export default {
  name: "NavBar",
  data() {
    return {
      blogImg,
    };
  },
  methods: {
    goToVasilBlogTemplate() {
      this.$router.push("/blog");
    },
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  padding-top: 128px;
  padding-bottom: 128px;
  background: #2d748c;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}
.container {
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}

.content {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  display: flex;
}
.headingAndSupportText-container {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.heading {
  text-align: center;
  color: white;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
}

.subheading {
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.threeBlogPosts-container {
  /* background-color: pink; */
  width: 90%;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}

.blogPost-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.threeBlogPosts-container img {
  width: 405.33px;
  height: 240px;
  position: relative;
  border-radius: 8px;
}

.blogPostInfo-container {
  width: 405.33334px;
  height: 124px;
  /* background-color: #2d8c61; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: -12px;
  display: inline-flex;
}

.authorAndDate {
  /* align-self: stretch; */
  /* background-color: violet; */
  text-align: start;
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 20px; */
  word-wrap: break-word;
}

.blogPostHeading {
  margin-top: 0px;
  /* background-color: red; */
  color: white;
  font-size: 24px;
  font-weight: 600;
  text-align: start;
  /* line-height: 32px; */
  word-wrap: break-word;
}

.blogPostDescription {
  margin-top: 0px;
  /* background-color: blue; */
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: start;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .content {
    width: 100%;
  }

  .headingAndSupportText-container {
    width: 80%;
  }

  .threeBlogPosts-container {
    width: 100%;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .headingAndSupportText-container {
    width: 80%;
  }

  .threeBlogPosts-container {
    justify-content: center;
    align-items: center;
  }

  .heading {
    font-size: 30px;
  }

  .subheading {
    font-size: 17px;
  }

  .blogPostHeading {
    text-align: center;
  }

  .authorAndDate {
    text-align: center;
  }

  .blogPostDescription {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .threeBlogPosts-container {
    justify-content: center;
    align-items: center;
  }

  .blogPost-container {
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .blogPostInfo-container {
    width: 80%;
  }

  .threeBlogPosts-container img {
    width: 80%;
  }

  .blogPostHeading {
    font-size: 20px;
  }

  .authorAndDate {
    font-size: 12px;
  }

  .blogPostDescription {
    font-size: 14px;
  }
}
</style>
