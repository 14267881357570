<template>
  <div class="main-container">
    <div class="container">
      <div class="content">
        <div class="headingAndSupportText-container">
          <p class="heading">{{ $t("ourTeam.meetOurTeam") }}</p>
          <p class="subheading">{{ $t("ourTeam.subtitle") }}</p>
        </div>
      </div>
    </div>

    <div class="team-container">
      <div class="first-row-container">
        <div class="member-container">
          <div class="inner-member-container">
            <div class="member-img-container">
              <!-- <div class="member-card"></div> -->
              <img class="member-image" :src="kiko" />
            </div>
            <div class="memberInfoAndApp-container">
              <div class="memberInfo-container">
                <div class="memberNameAndRole-container">
                  <p class="member-name">{{ $t("members.name.row1col1") }}</p>
                  <p class="member-role">{{ $t("members.role.row1col1") }}</p>
                </div>
                <p class="member-mini-info">
                  {{ $t("members.info.row1col1") }}
                </p>
              </div>
              <div class="app-container">
                <img
                  class="app-icon"
                  :src="linkedInIcon"
                  @click="
                    openInNewTab('https://www.linkedin.com/in/popovski-k/')
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="member-container">
          <div class="inner-member-container">
            <div class="member-img-container">
              <!-- <div class="member-card"></div> -->
              <img class="member-image" :src="monika" />
            </div>
            <div class="memberInfoAndApp-container">
              <div class="memberInfo-container">
                <div class="memberNameAndRole-container">
                  <p class="member-name">{{ $t("members.name.row1col2") }}</p>
                  <p class="member-role">{{ $t("members.role.row1col2") }}</p>
                </div>
                <p class="member-mini-info">
                  {{ $t("members.info.row1col2") }}
                </p>
              </div>
              <div class="app-container">
                <img
                  class="app-icon"
                  :src="linkedInIcon"
                  @click="
                    openInNewTab(
                      'https://www.linkedin.com/in/monika-velinova-21632517a/'
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="member-container">
          <div class="inner-member-container">
            <div class="member-img-container">
              <!-- <div class="member-card"></div> -->
              <img class="member-image" :src="lea" />
            </div>
            <div class="memberInfoAndApp-container">
              <div class="memberInfo-container">
                <div class="memberNameAndRole-container">
                  <p class="member-name">{{ $t("members.name.row1col3") }}</p>
                  <p class="member-role">{{ $t("members.role.row1col3") }}</p>
                </div>
                <p class="member-mini-info">
                  {{ $t("members.info.row1col3") }}
                </p>
              </div>
              <div class="app-container">
                <img
                  style="display: none"
                  class="app-icon"
                  :src="linkedInIcon"
                  @click="
                    openInNewTab('https://www.linkedin.com/in/popovski-k/')
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="member-container">
          <div class="inner-member-container">
            <div class="member-img-container">
              <!-- <div class="member-card"></div> -->
              <img class="member-image" :src="marija" />
            </div>
            <div class="memberInfoAndApp-container">
              <div class="memberInfo-container">
                <div class="memberNameAndRole-container">
                  <p class="member-name">{{ $t("members.name.row1col4") }}</p>
                  <p class="member-role">{{ $t("members.role.row1col4") }}</p>
                </div>
                <p class="member-mini-info">
                  {{ $t("members.info.row1col4") }}
                </p>
              </div>
              <div class="app-container">
                <img
                  class="app-icon"
                  :src="linkedInIcon"
                  @click="
                    openInNewTab(
                      'https://www.linkedin.com/in/marija-koshtrevska/'
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="member-container">
          <div class="inner-member-container">
            <div class="member-img-container">
              <!-- <div class="member-card"></div> -->
              <img class="member-image" :src="vasil" />
            </div>
            <div class="memberInfoAndApp-container">
              <div class="memberInfo-container">
                <div class="memberNameAndRole-container">
                  <p class="member-name">{{ $t("members.name.row1col5") }}</p>
                  <p class="member-role">{{ $t("members.role.row1col5") }}</p>
                </div>
                <p class="member-mini-info">
                  {{ $t("members.info.row1col5") }}
                </p>
              </div>
              <div class="app-container">
                <img
                  style="display: none"
                  class="app-icon"
                  :src="linkedInIcon"
                  @click="
                    openInNewTab('https://www.linkedin.com/in/popovski-k/')
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="member-container">
          <div class="inner-member-container">
            <div class="member-img-container">
              <!-- <div class="member-card"></div> -->
              <img class="member-image" :src="marko" />
            </div>
            <div class="memberInfoAndApp-container">
              <div class="memberInfo-container">
                <div class="memberNameAndRole-container">
                  <p class="member-name">{{ $t("members.name.row1col6") }}</p>
                  <p class="member-role">{{ $t("members.role.row1col6") }}</p>
                </div>
                <p class="member-mini-info">
                  {{ $t("members.info.row1col6") }}
                </p>
              </div>
              <div class="app-container">
                <img
                  style="display: none"
                  class="app-icon"
                  :src="linkedInIcon"
                  @click="
                    openInNewTab('https://www.linkedin.com/in/popovski-k/')
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import teamMember from "@/assets/images/teamMember.png";
import linkedInIcon from "@/assets/images/linkedIn_icon.svg";
import site from "@/assets/images/msosSite.webp";
// import kiko from "@/assets/images/kiko.png"
import kiko from "@/assets/images/2. About Us page/2. Chlenovi na Uprava/1. Kristijan Popovski.jpg";
import marija from "@/assets/images/2. About Us page/2. Chlenovi na Uprava/5.-Marija-Koshtrevska.jpeg";
import marko from "@/assets/images/2. About Us page/2. Chlenovi na Uprava/4. Marko Arsov.jpg";
import monika from "@/assets/images/2. About Us page/2. Chlenovi na Uprava/3. Monika Velinova.jpeg";
import lea from "@/assets/images/2. About Us page/2. Chlenovi na Uprava/2. Lea Janachkovska.jpg";
import vasil from "@/assets/images/vasil.jpeg";

export default {
  name: "NavBar",
  data() {
    return {
      teamMember,
      linkedInIcon,
      site,
      kiko,
      marija,
      marko,
      monika,
      lea,
      vasil,
    };
  },
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}

.content {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  display: flex;
}
.headingAndSupportText-container {
  width: 85%;
  height: 124px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.heading {
  text-align: center;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
}

.subheading {
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.team-container {
  width: 90%;
  /* padding-left: 24px;
  padding-right: 24px; */
  margin-top: 144px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 64px;
  display: inline-flex;
}

.first-row-container {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}

.second-row-container {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
}

.member-container {
  width: 19%;
  height: 320px;
  /* padding: 24px; */
  background: #f9fafb;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  display: inline-flex;
  padding-bottom: 15px;
}

.inner-member-container {
  width: 95%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.member-img-container {
  width: 80px;
  height: 80px;
  /* border-radius: 50%; */
  position: relative;
}

.member-img-container img {
  border-radius: 50px;
}

.member-image {
  width: 80px;
  height: 80px;
}

.member-card {
  width: 90px;
  height: 180px;
  left: 0px;
  top: 0px;
  position: absolute;
  background: #d9d9d9;
  border-radius: 9999px;
}

.memberInfoAndApp-container {
  /* background-color: yellow; */
  align-self: stretch;
  height: 168px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
}

.memberInfo-container {
  /* background-color: red; */
  align-self: stretch;
  height: 140px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.memberNameAndRole-container {
  align-self: stretch;
  height: 52px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.member-name {
  margin-top: 0px;
  margin-bottom: 0px;
  align-self: stretch;
  text-align: center;
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

.member-role {
  margin-top: 5px;
  align-self: stretch;
  text-align: center;
  color: #fa7850;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.member-mini-info {
  margin-top: 30px;
  margin-bottom: 5px;
  align-self: stretch;
  text-align: center;
  color: #667085;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.app-container {
  margin-top: 10px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
}

.app-icon {
  cursor: pointer;
  margin-top: 8px;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* .msos-everyone {
  width: 100%;
  border-radius: 24px;
} */

@media only screen and (max-width: 1024px) {
  .first-row-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .member-container {
    width: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .member-container {
    width: 55%;
  }
}
@media only screen and (max-width: 480px) {
  .member-container {
    width: 58%;
  }
  .heading {
    font-size: 30px;
  }

  .subheading {
    font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .member-container {
    width: 70%;
  }
}
</style>
