<template>
  <div class="main-container">
    <div class="content">
      <div class="headingAndSupportText-container">
        <p class="heading">{{ $t("form.heading") }}</p>
        <p class="subheading">{{ $t("form.subtitle") }}</p>
      </div>
    </div>
    <div class="image-container">
      <img class="form-img" :src="form_img" />
    </div>

    <div class="main-form-container">
      <div class="inner-form-container">
        <div class="formFields">
          <div class="firstNameLastName-row">
            <div class="field-container">
              <div class="field-label">{{ $t("form.firstName") }}</div>
              <div class="field-input-container">
                <input
                  class="field-input"
                  type="text"
                  placeholder="First Name"
                  v-model="name"
                />
              </div>
            </div>
            <div class="field-container">
              <div class="field-label">{{ $t("form.lastName") }}</div>
              <div class="field-input-container">
                <input
                  class="field-input"
                  type="text"
                  placeholder="Last Name"
                  v-model="surname"
                />
              </div>
            </div>
          </div>
          <div class="other-fileds">
            <div class="field-container emailField">
              <div class="field-label">{{ $t("form.email") }}</div>
              <div class="field-input-container emailField">
                <input
                  class="field-input"
                  type="email"
                  placeholder="student@gmail.com"
                  v-model="email"
                  @input="validateEmail()"
                />
              </div>
              <div class="valid-email" v-if="!validEmail">
                Please enter a valid email address.
              </div>
            </div>
            <div class="field-container messageField">
              <div class="field-label">{{ $t("form.message") }}</div>
              <div class="field-input-container messageField">
                <textarea
                  class="textArea"
                  rows="4"
                  v-model="message"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="button-container">
            <button
              class="button-inner button-text"
              :disabled="!validEmail || !email || !name || !message"
              @click="sendEmail()"
            >
              {{ $t("form.sendMessage") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import form_img from "@/assets/images/form_image.png";
import axios from "axios";

export default {
  name: "FormComponent",
  data() {
    return {
      form_img,
      name: "",
      surname: "",
      email: "",
      message: "",
      validEmail: true
    };
  },
  methods: {
    sendEmail() {
      // this.valid = this.name != null || this.email != null || this.message != null
      axios
        .post("https://api.msosorg.com/send-email/", {
          name: `${this.name} ${this.surname}`,
          email: this.email,
          message: this.message,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validateEmail() {
      if (
        this.email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        this.validEmail = true;
      } else {
        this.validEmail = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  padding-top: 64px;
  padding-bottom: 128px;
  background: #f9fafb;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}

.valid-email {
  color: #ff9090;
  padding: 8px;
}

.content {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
.headingAndSupportText-container {
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.heading {
  margin-bottom: 0px;
  text-align: center;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.subheading {
  margin-top: 0px;
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.image-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  display: flex;
}

img {
  width: 80%;
  border-radius: 16px;
}

.main-form-container {
  /* background-color: aquamarine; */
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inner-form-container {
  /* background-color: yellow; */
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}

.formFields {
  /* background-color: beige; */
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: inline-flex;
}

.firstNameLastName-row {
  width: 80%;
  /* background-color: red; */
  justify-content: space-around;
  align-items: center;
  gap: 33px;
  display: flex;
}

.field-container {
  /* background-color: rebeccapurple; */
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.field-label {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  margin-bottom: 6px;
}

.field-input-container {
  width: 100%;
  /* padding-left: 16px;
  padding-right: 16px; */
  padding-top: 12px;
  padding-bottom: 12px;
  background: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #d0d5dd solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.field-input {
  flex: 1 1 0;
  height: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  border: none;
  font-size: 16px;
  outline: none;
  padding-left: 16px;
  padding-right: 16px;
  /*  */
}

input[type="email"]::placeholder,
input[type="text"]::placeholder {
  color: #646e82;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.other-fileds {
  display: flex;
  justify-content: center;
  width: 80%;
  flex-direction: column;
}

/* .emailField {
  width: 90%;
} */

.messageField {
  /* width: 90%; */
  height: 128px;
}

.textArea {
  outline: none;
  resize: none;
  vertical-align: top;
  line-height: 1.5;
  align-self: stretch;
  flex: 1 1 0;
  background: white;
  border-radius: 8px;
  border: none;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

/* Ako postoi placeholder */
.textArea::placeholder {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.textArea {
  font-family: Inter, sans-serif;
}

.button-inner {
  flex: 1 1 0;
  height: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #2d738c;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 1px #2d738c solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.button-text {
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

button:disabled {
  background: gray;
}

.button-container {
  width: 80%;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

@media only screen and (max-width: 768px) {
  .firstNameLastName-row {
    flex-direction: column;
  }

  .formFields {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  img {
    display: none;
  }

  .main-container {
    gap: 20px;
  }

  .heading {
    font-size: 27px;
  }

  .subheading {
    font-size: 15px;
  }
}
</style>
