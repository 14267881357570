<template>
  <div class="main-container">
    <div class="inner-container">
      <div class="left-container">
        <div class="headingAndSubtitle-container">
          <div class="heading">{{ $t("FAQsStillHaveQuestions.heading") }}</div>
          <div class="subtitle">
            {{ $t("FAQsStillHaveQuestions.subtitle") }}
          </div>
        </div>
        <button @click="goToContactsPage" class="button-container">{{ $t("FAQsStillHaveQuestions.getInTouch") }}</button>
      </div>
      <div class="right-container">
        <img class="kiko-image" :src="hmillano" />
      </div>
    </div>
  </div>
</template>

<script>
import msosLogo from "@/assets/images/msos_logo.png";
import hmillano from "@/assets/images/3. FAQs page/1. dole levo.jpg"

export default {
  name: "NavBar",
  data() {
    return {
      msosLogo,
      hmillano,
    };
  },
  methods: {
    goToContactsPage() {
      this.$router.push('/contact');
    }
  }
};
</script>

<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}

.inner-container {
  width: 90%;
  padding-left: 24px;
  padding-right: 32px;
  justify-content: center;
  align-items: center;
  gap: 48px;
  display: inline-flex;
}

.left-container {
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  display: inline-flex;
}

.headingAndSubtitle-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.heading {
  height: 44px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;

  align-self: stretch;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
}

.subtitle {
  width: 95%;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
  text-align: start;
}

.right-container {
  background-color: inherit;
  width: 50%;
  /* height: 300px; */
}

.kiko-image {
  border-radius: 5%;
  width: 62.5%;
  height: 100%;
}

.button-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #2d738c;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 1px #7f56d9 solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .inner-container {
    flex-direction: column;
    align-items: center;
  }
  .left-container {
    width: 70%;
  }

  .right-container {
    width: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .left-container {
    width: 80%;
  }

  .heading {
    font-size: 32px;
  }

  .subtitle {
    font-size: 18px;
  }

  .right-container {
    width: 80%;
  }
}
@media only screen and (max-width: 480px) {
  .heading {
    font-size: 30px;
  }

  .subtitle {
    /* width: 90%; */
    font-size: 16px;
    line-height: 25px;
  }
}
</style>
