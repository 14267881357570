<template>
  <div class="container">
    <!-- Section for Date and Title -->
    <section class="section-one">
      <header class="header">
        <div class="dateStyle">{{ $t("VasilBlogTemplate.date") }}</div>
        <div class="titleStyle">{{ $t("VasilBlogTemplate.title") }}</div>
      </header>
    </section>

    <!-- Add a photo section here -->
    <section class="photo-section">
      <img class="featured-image" :src="MariborSlika" alt="Maribor City" />
    </section>

    <!-- Paragraphs -->
    <section v-for="i in 7" :key="i" class="klasa9">
      <span class="klasa21">{{ $t(`VasilBlogTemplate.paragraph${i}`) }}</span>
    </section>

    <!-- Author section -->
    <div class="klasa1">
      <div class="klasa36">
        <img class="klasa38" :src="Vasil" />
        <div class="klasa39">
          <div class="klasa40">{{ $t("VasilBlogTemplate.author") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vasil from "@/assets/images/vasil.jpeg";
import MariborSlika from "@/assets/images/maribor.jpg";
export default {
  name: "NavBar",
  data() {
    return {
      Vasil,
      MariborSlika,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
}

.section-one {
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dateStyle, .titleStyle {
  text-align: center;
}

.dateStyle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fa7850;
  margin-bottom: 24px;
}

.titleStyle {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 16px;
}

.photo-section {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.featured-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover; 
}

.klasa9 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #101828;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  word-wrap: break-word;
  text-align: center;
  max-width: 80%;
  margin-bottom: 24px; 
}

.klasa21 {
  text-align: center;
  color: #667085;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
  max-width: 80%;
  margin: 0 auto;
}

.klasa1 {
  padding-bottom: 96px;
  display: flex;
  justify-content: center;
}

.klasa36 {
  display: flex;
  align-items: center;
  gap: 16px;
}

.klasa38 {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.klasa40 {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
}

/* Media queries */

@media (max-width: 1024px) {
  .container {
    padding: 0 40px; 
  }

  .featured-image {
    max-width: 90%; 
  }

  .klasa9 {
    font-size: 26px;
    line-height: 34px;
    max-width: 90%; 
  }

  .klasa21 {
    font-size: 16px;
    line-height: 26px;
    max-width: 90%;
  }

  .titleStyle {
    font-size: 32px;
    line-height: 40px;
  }

  .dateStyle {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 20px; 
  }

  .section-one {
    padding: 72px 0;
  }

  .featured-image {
    max-width: 95%; 
  }

  .klasa9 {
    font-size: 22px;
    line-height: 30px;
    max-width: 95%;
  }

  .klasa21 {
    font-size: 14px;
    line-height: 24px;
    max-width: 95%;
  }

  .titleStyle {
    font-size: 28px;
    line-height: 36px;
  }

  .dateStyle {
    font-size: 13px;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 10px; 
  }

  .section-one {
    padding: 48px 0;
  }

  .featured-image {
    max-width: 100%; 
    border-radius: 4px; 
  }

  .klasa9 {
    font-size: 18px;
    line-height: 26px;
    max-width: 100%; 
    padding: 5px;
  }

  .klasa21 {
    font-size: 12px;
    line-height: 20px;
    max-width: 100%;
  }

  .titleStyle {
    font-size: 24px;
    line-height: 32px;
  }

  .dateStyle {
    font-size: 12px;
    line-height: 18px;
  }

  .klasa36 {
    flex-direction: column; 
    gap: 8px;
  }

  .klasa38 {
    width: 48px;
    height: 48px;
  }

  .klasa40 {
    font-size: 16px;
  }
}

</style>
