import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import mkMessages from "./locales/mk.json";
import enMessages from "./locales/en.json";
import siMessages from "./locales/si.json";
import * as VueRouter from "vue-router";
import HomePage from "@/sites/HomePage.vue";
import AboutUs from "@/sites/AboutUs.vue";
import SiteNotFound from "@/sites/SiteNotFound.vue";
import UnderConstruction from "@/sites/UnderConstruction.vue";
// import BlogPage from "@/sites/BlogPage.vue";
import FAQs from "@/sites/FAQsPage.vue";
import Contact from "@/sites/Contact.vue";
import BlogTemplate from "@/sites/BlogTemplate.vue";
import Form from "./components/Form.vue";
import VasilBlogTemplate from "./components/VasilBlogTemplate.vue";

/* fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* specific icons */
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faCaretDown);
library.add(faCaretUp);
library.add(faCircleChevronDown);
library.add(faBars);

const i18n = createI18n({
  locale: "mk",
  messages: {
    en: enMessages,
    mk: mkMessages,
    si: siMessages,
  },
});
console.log(i18n.messages);

const app = createApp(App);
app.use(i18n);
app.use(
  VueRouter.createRouter({
    history: VueRouter.createWebHistory(process.env.BASE_URL),
    routes: [
      {
        path: "",
        component: HomePage,
      },
      {
        path: "/about-us",
        component: AboutUs,
      },
      {
        path: "/students",
        component: FAQs,
      },
      {
        path: "/blog",
        component: VasilBlogTemplate,
      },
      {
        path: "/brochure",
        component: UnderConstruction,
      },
      {
        path: "/underConstruction",
        component: UnderConstruction,
      },
      {
        path: "/projects",
        component: UnderConstruction,
      },
      {
        path: "/contact",
        component: Contact,
      },
      {
        path: "/:pathMatch(.*)*",
        component: SiteNotFound,
      },
      {
        path: "/blog",
        component: BlogTemplate,
      },
      {
        path: "/public-announcements",
        component: UnderConstruction,
      },
      {
        path: "/organisation-identity",
        component: UnderConstruction,
      },
      {
        path: "/contact",
        component: UnderConstruction,
      },
      {
        path: "/brochure",
        component: UnderConstruction,
      },
      {
        path: "/gallery",
        component: UnderConstruction,
      },
      {
        path: "/form",
        name: "Form",
        component: Form,
      },
    ],
  })
);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
