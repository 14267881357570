<template>
  <div class="main-container">
    <div class="container">
      <div class="content">
        <div class="headingAndSupportText-container">
          <p class="heading">{{ $t("FAQHomePage.heading") }}</p>
          <!-- <p class="subheading">{{ $t("FAQHomePage.subtitle") }}</p> -->
        </div>
      </div>
    </div>

    <div class="FAQcards-container">
      <!-- cards : first row -->
      <div class="miniCards-container">
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="question_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("FAQHomePage.cardHeading.1") }}</p>
            <p class="card-description">
              {{ $t("FAQHomePage.cardDescription.1") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="question_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("FAQHomePage.cardHeading.2") }}</p>
            <p class="card-description">
              {{ $t("FAQHomePage.cardDescription.2") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="question_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("FAQHomePage.cardHeading.3") }}</p>
            <p class="card-description">
              {{ $t("FAQHomePage.cardDescription.3") }}
            </p>
          </div>
        </div>
      </div>

      <!--cards: 2 row -->
      <div class="miniCards-container">
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="question_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("FAQHomePage.cardHeading.4") }}</p>
            <p class="card-description">
              {{ $t("FAQHomePage.cardDescription.4") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="question_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("FAQHomePage.cardHeading.5") }}</p>
            <p class="card-description">
              {{ $t("FAQHomePage.cardDescription.5") }}
            </p>
          </div>
        </div>
        <div class="card-container">
          <div class="icon-container">
            <img class="img-icon" :src="question_icon" />
          </div>
          <div class="miniCard-content-container">
            <p class="card-heading">{{ $t("FAQHomePage.cardHeading.6") }}</p>
            <p class="card-description">
              {{ $t("FAQHomePage.cardDescription.6") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="stillHaveQuestions-main-container">
      <!-- Add photo -->
      <div class="miniImages-container"></div>
      <div class="stillHaveQuestions-container">
        <p class="stillHaveQuestions-heading">
          {{ $t("FAQHomePage.stillHaveQuestions.header") }}
        </p>
        <p class="stillHaveQuestions-subtitle">
          {{ $t("FAQHomePage.stillHaveQuestions.subtitle") }}
        </p>
      </div>
      <div class="button-container">
        <button @click="goToFAQs" class="inner-button">
          {{ $t("FAQHomePage.stillHaveQuestions.seeAllFAQs") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import question_icon from "@/assets/images/faq_icon.svg";

export default {
  name: "NavBar",
  data() {
    return {
      question_icon,
    };
  },
  methods: {
    goToFAQs() {
      this.$router.push('/students');
    }
  }
};
</script>

<style scoped>
.main-container {
  width: 100%;
  padding-top: 128px;
  padding-bottom: 128px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}
.container {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
}

.content {
  width: 95%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  display: flex;
}
.headingAndSupportText-container {
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.heading {
  margin-bottom: 0px;
  text-align: center;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
}

.subheading {
  margin-top: 0px;
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.FAQcards-container {
  /* background-color: yellow; */
  width: 90%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  margin-top: 64px;
  display: inline-flex;
}

.miniCards-container {
  /* background-color: aquamarine; */
  width: 95%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.card-container {
  width: 45%;
  /* background-color: rebeccapurple; */
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}

.icon-container {
  padding: 12px;
  background: rgba(39, 77, 90, 0.08);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.img-icon {
  width: 24px;
  height: 24px;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.miniCard-content-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.card-heading {
  margin-top: 0px;
  text-align: start;
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  /* line-height: 30px; */
  word-wrap: break-word;
}

.card-description {
  margin-top: 0px;
  align-self: stretch;
  color: #667085;
  font-size: 16px;
  text-align: start;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.stillHaveQuestions-main-container {
  width: 90%;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f9fafb;
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: inline-flex;
}

.stillHaveQuestions-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  width: 80%;
}

.stillHaveQuestions-heading {
  margin-bottom: 0px;
  text-align: center;
  color: #101828;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  word-wrap: break-word;
}

.stillHaveQuestions-subtitle {
  margin-top: 0px;
  text-align: center;
  color: #667085;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.button-container {
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.inner-button {
  cursor: pointer;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fa7850;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #fa7850 solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;

  /* button text */
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 768px) {
  .miniCards-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .FAQcards-container {
    gap: 32px;
  }

  .card-container {
    justify-content: center;
    align-items: center;
    width: 65%;
  }

  .miniCard-content-container {
    align-items: center;
  }

  .card-heading {
    text-align: center;
  }
  .card-description {
    text-align: center;
  }

  .heading {
    font-size: 30px;
  }

  .subheading {
    font-size: 18px;
  }

  .main-container {
    gap: 0px;
  }

  .stillHaveQuestions-heading {
    font-size: 20px;
  }

  .stillHaveQuestions-subtitle {
    font-size: 16px;
  }
}
@media only screen and (max-width: 480px) {
  .card-container {
    width: 75%;
  }
}
</style>
