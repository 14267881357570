<template>
  <body>
    <FAQsHeader />
    <FAQsAllQuestions />
    <FAQsAskMore />
  </body>
</template>

<script>
import FAQsHeader from "@/components/FAQsHeader.vue";
import FAQsAllQuestions from "@/components/FAQsAllQuestions.vue";
import FAQsAskMore from "@/components/FAQsStillHaveQuestions.vue";

export default {
  name: "NavBar",
  components: {
    FAQsHeader,
    FAQsAllQuestions,
    FAQsAskMore,
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style></style>
