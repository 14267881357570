<template>
<div class="container">
  <div class="section-one">
    <div class="section-one-inner">
      <div class="header">
        <div class="header-title-one">
          <div class="header-title-two">
            <div class="dateStyle">{{ $t("BlogTemplate.date") }}</div>
            <div class="titleStyle">{{ $t("BlogTemplate.title") }}</div>
          </div>
          <div class="subtitleStyle">{{ $t("BlogTemplate.subtitle") }}</div>
        </div>
      </div>
    </div>
    <div class="section-two">
      <div class="section-two-inner">
        <img class="imageHeader" :src="blogHeader" />
      </div>
    </div>
  </div>
  <div class="klasa1">
    <div class="klasa2">
      <div class="klasa3">
        <div class="klasa4">
          <div class="klasa5">
            <div class="klasa6">{{ $t("BlogTemplate.description") }}</div>
            <div class="klasa7"></div>
          </div>
          <div class="klasa8">
            <div class="klasa9">{{ $t("BlogTemplate.introTitle") }}</div>
            <div style="align-self: stretch">
              <span class="klasa10">{{ $t("BlogTemplate.introductionOne") }}<br/>{{ $t("BlogTemplate.introductionTwo") }}
              </span>
            </div>
          </div>
          <div class="klasa11">
            <img class="klasa12" :src="BlogOne" />
            <div style="align-self: stretch">
              <span class="klasa13">{{ $t("BlogTemplate.imageOneCaption") }}</span>
              <span class="klasa14">{{ $t("BlogTemplate.imageOneCaptionSource") }}</span>
            </div>
          </div>
          <div class="klasa15">
            <div class="klasa16"></div>
            <div class="klasa17">
              <div class="klasa18">{{ $t("BlogTemplate.quote") }}</div>
              <div class="klasa19">{{ $t("BlogTemplate.quoteAuthor") }}</div>
            </div>
          </div>
          <div class="klasa20">
            <div style="align-self: stretch">
              <span class="klasa21">{{ $t("BlogTemplate.paraOne") }}</span>
            </div>
          </div>
          <div class="klasa22">
            <div class="klasa23">{{ $t("BlogTemplate.sectionOneTitle") }}</div>
            <div class="klasa24">{{ $t("BlogTemplate.sectionOne") }}</div>
          </div>
          <div class="klasa25">
            <div class="klasa26">{{ $t("BlogTemplate.sectionOneTitle") }}</div>
            <div style="align-self: stretch">
              <span class="klasa27">{{ $t("BlogTemplate.sectionTwo") }}</span>
            </div>
          </div>
          <div class="klasa28">
            <img class="klasa29" :src="BlogTwo" />
            <div style="align-self: stretch">
              <span class="klasa30">{{ $t("BlogTemplate.imageTwoCaption") }}</span>
              <span class="klasa31">{{ $t("BlogTemplate.imageTwoCaptionSource") }}</span>
            </div>
          </div>
          <div class="klasa32">
            <div class="klasa33">{{ $t("BlogTemplate.conclusion") }}</div>
          </div>
          <div class="klasa34">
            <div class="klasa35"></div>
            <div class="klasa36">
              <div class="klasa37">
                <img class="klasa38" :src="Vasil" />
                <div class="klasa39">
                  <div class="klasa40">{{ $t("BlogTemplate.authorName") }}</div>
                  <div class="klasa41">{{ $t("BlogTemplate.authorCredentials") }}</div>
                </div>
              </div>
              <div class="klasa42">
             <div class="klasa43">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
  
<script>
  import line from "@/assets/images/line.svg";
  import activitiesImg from "@/assets/images/activitiesImgg.png";
  import blogHeader from "@/assets/images/blogHeader.png";
  import BlogOne from "@/assets/images/BlogOne.png";
  import BlogTwo from "@/assets/images/BlogTwo.png";
  import Vasil from "@/assets/images/vasil.jpeg";
  export default {
    name: "NavBar",
    data() {
      return {
        line,
        activitiesImg,
        blogHeader,
        BlogOne,
        BlogTwo,
        Vasil,
      };
    },
  };
</script>

<style scoped>

.container {
  width: 1440px;
  height: 4328px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.section-one {
  align-self: stretch;
  height: 922px;
  padding-top: 96px;
  padding-bottom: 96px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: flex;
}

.section-one-inner {
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: flex;
}

.header {
  align-self: stretch;
  height: 150px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  display: flex;
}

.header-title-one {
  height: 150px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: flex;
}

.header-title-two {
  align-self: stretch;
  height: 96px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.dateStyle {
  align-self: stretch;
  text-align: center;
  color: #FA7850;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.titleStyle {
  align-self: stretch;
  text-align: center;
  color: #101828;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  word-wrap: break-word;
}

.subtitleStyle {
  align-self: stretch;
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.imageHeader {
  width: 1216px;
  height: 516px;
  position: relative;
  border-radius: 24px;
}

.section-two {
  height: 516px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: flex;
}

.section-two-inner {
  align-self: stretch;
  height: 516px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 48px;
  display: flex;
}

.klasa1 {
  align-self: stretch; 
  height: 3406px; 
  padding-bottom: 96px; 
  background: white; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: center; 
  gap: 64px; 
  display: flex
}

.klasa2 {
  height: 3310px; 
  padding-left: 32px; 
  padding-right: 32px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: center; 
  gap: 64px; 
  display: flex
}

.klasa3 {
  align-self: stretch; 
  height: 3310px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: center; 
  gap: 48px; 
  display: flex
}

.klasa4 {
  height: 3310px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 48px; 
  display: flex
}

.klasa5 {
  align-self: stretch; 
  height: 153px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 32px; 
  display: flex
}

.klasa6 {
  align-self: stretch; 
  color: #667085; 
  font-size: 20px; 
  font-weight: 400; 
  line-height: 30px; 
  word-wrap: break-word
}

.klasa7 {
  align-self: stretch; 
  height: 1px; 
  background: #EAECF0
}

.klasa8 {
  align-self: stretch; 
  height: 304px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 24px; 
  display: flex
}

.klasa9 {
  align-self: stretch; 
  color: #101828; 
  font-size: 30px; 
  font-weight: 600; 
  line-height: 38px; 
  word-wrap: break-word
}

.klasa10 {
  color: #667085; 
  font-size: 18px; 
  font-weight: 400; 
  line-height: 28px; 
  word-wrap: break-word
}

.klasa11 {
  align-self: stretch; 
  height: 516px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 16px; 
  display: flex
}

.klasa12 {
  width: 720px; 
  height: 480px; 
  position: relative; 
  align-self: center; 
  border-radius: 16px
}

.klasa13 {
  color: #667085; 
  font-size: 14px; 
  font-weight: 400; 
  line-height: 20px; 
  word-wrap: break-word
}

.klasa14 {
  color: #667085; 
  font-size: 14px; 
  font-weight: 400; 
  text-decoration: underline; 
  line-height: 20px; 
  word-wrap: break-word
}

.klasa15 {
  align-self: stretch; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 20px; 
  display: inline-flex
}

.klasa16 {
  width: 2px; 
  align-self: stretch; 
  background: #D0D5DD
}

.klasa17 {
  flex: 1 1 0; 
  padding-top: 8px; 
  padding-bottom: 8px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 32px; 
  display: inline-flex
}

.klasa18 {
  align-self: stretch; 
  color: #101828; 
  font-size: 24px; 
  font-style: italic; 
  font-weight: 500; 
  line-height: 36px; 
  word-wrap: break-word
}

.klasa19 {
  align-self: stretch; 
  color: #FA7850; 
  font-size: 16px; 
  font-weight: 400; 
  line-height: 24px; 
  word-wrap: break-word
}

.klasa20 {
  align-self: stretch; 
  height: 400px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 24px; 
  display: flex
}

.klasa21 {
  color: #667085; 
  font-size: 18px; 
  font-weight: 400; 
  line-height: 28px; 
  word-wrap: break-word
}

.klasa22 {
  align-self: stretch; 
  height: 132px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 16px; 
  display: flex
}

.klasa23 {
  align-self: stretch; 
  color: #101828; 
  font-size: 24px; 
  font-weight: 600; 
  line-height: 32px; 
  word-wrap: break-word
}

.klasa24 {
  align-self: stretch; 
  color: #667085; 
  font-size: 18px; 
  font-weight: 400; 
  line-height: 28px; 
  word-wrap: break-word
}

.klasa25 {
  align-self: stretch; 
  height: 318px; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 16px; 
  display: flex
}

.klasa26 {
  align-self: stretch;
  color: #101828;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  word-wrap: break-word;  
}

.klasa27 {
  color: #667085;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.klasa28 {
  align-self: stretch;
  height: 516px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.klasa29 {
  width: 720px;
  height: 480px;
  position: relative;
  align-self: center;
  border-radius: 16px;
}

.klasa30 {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.klasa31 {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  line-height: 20px;
  word-wrap: break-word;
}

.klasa32 {
  align-self: stretch;
  height: 242px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.klasa33 {
  align-self: stretch;
  color: #667085;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.klasa34 {
  align-self: stretch;
  height: 81px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.klasa35 {
  align-self: stretch;
  height: 1px;
  background: #EAECF0;
}

.klasa36 {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.klasa37 {
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
}

.klasa38 {
  width: 56px;
  height: 56px;
  border-radius: 200px;
}

.klasa39 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.klasa40 {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

.klasa41 {
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.klasa42 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.klasa43 {
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 1024px) {
  .container {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .section-one {
    height: auto;
    padding-top: 48px;
    padding-bottom: 48px;
    gap: 32px;
  }

  .section-one-inner {
    padding-left: 16px;
    padding-right: 16px;
    gap: 16px;
  }

  .header {
    height: auto;
    gap: 20px;
  }

  .header-title-one {
    height: auto;
    gap: 12px;
  }

  .header-title-two {
    height: auto;
    gap: 6px;
  }

  .dateStyle, .titleStyle, .subtitleStyle {
    font-size: calc(0.7em + 1vw);
    line-height: calc(0.9em + 1vw);
  }

  .imageHeader {
    width: 100%;
    height: auto;
  }

  .section-two {
    height: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  .section-two-inner {
    height: auto;
    gap: 24px;
  }

  .klasa1, .klasa2, .klasa3, .klasa4 {
    height: auto;
    padding-bottom: 48px;
    gap: 32px;
  }

  .klasa5, .klasa8, .klasa11, .klasa15, .klasa20, .klasa22, .klasa25, .klasa28, .klasa32, .klasa34 {
    height: auto;
    gap: 16px;
  }

  .klasa12, .klasa29 {
    width: 100%;
    height: auto;
  }

  .klasa17, .klasa37 {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .section-one {
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 16px;
  }

  .section-one-inner {
    padding-left: 8px;
    padding-right: 8px;
    gap: 8px;
  }

  .header {
    gap: 10px;
  }

  .header-title-one {
    gap: 6px;
  }

  .header-title-two {
    gap: 3px;
  }

  .dateStyle, .titleStyle, .subtitleStyle {
    font-size: calc(0.5em + 1vw);
    line-height: calc(0.7em + 1vw);
  }

  .imageHeader {
    border-radius: 12px;
  }

  .section-two {
    padding-left: 8px;
    padding-right: 8px;
  }

  .section-two-inner {
    gap: 12px;
  }

  .klasa1, .klasa2, .klasa3, .klasa4 {
    padding-bottom: 24px;
    gap: 16px;
  }

  .klasa5, .klasa8, .klasa11, .klasa15, .klasa20, .klasa22, .klasa25, .klasa28, .klasa32, .klasa34 {
    gap: 8px;
  }

  .klasa12, .klasa29 {
    border-radius: 8px;
  }

  .klasa17, .klasa37 {
    flex-direction: column;
    align-items: center;
  }
}


</style>