<template>
  <div class="main-container">
    <div class="main-inner-container">
      <div class="container">
        <div class="content">
          <div class="headingAndSupportText-container">
            <p class="heading">{{ $t("activitiesSection.heading") }}</p>
            <p class="subheading">{{ $t("activitiesSection.subtitle") }}</p>
          </div>
        </div>
      </div>
      <div class="activites-content-container">
        <div class="left-container">
          <div class="upper-part-left-container">
            <div class="line-container">
              <img class="line" :src="line" />
            </div>

            <div class="listedActivities-container">
              <p
                :class="{
                  selectedActivity: activities[0],
                  activity: !activities[0],
                }"
                @click="switchActivity(0)"
              >
                {{ $t("activitiesSection.activity1") }}
              </p>
              <p
                :class="{
                  selectedActivity: activities[1],
                  activity: !activities[1],
                }"
                @click="switchActivity(1)"
              >
                {{ $t("activitiesSection.activity2") }}
              </p>
              <p
                :class="{
                  selectedActivity: activities[2],
                  activity: !activities[2],
                }"
                @click="switchActivity(2)"
              >
                {{ $t("activitiesSection.activity3") }}
              </p>
              <p
                :class="{
                  selectedActivity: activities[3],
                  activity: !activities[3],
                }"
                @click="switchActivity(3)"
              >
                {{ $t("activitiesSection.activity4") }}
              </p>
            </div>
          </div>

          <div class="button-container">
            <button
          @click="goToFormSection" class="becomeAMemberButton">
          <div class="becomeAMemberButton-inner">
            {{ $t("homePage.becomeAMember") }}
          </div>
        </button>

          </div>
        </div>
        <div class="left-container-small-screens">
          <div class="listedActivities-container">
            <select
              class="select-container"
              @change="switchActivity($event.target.value)"
            >
              <option class="option-css" :value="0" :selected="activities[0]">
                {{ $t("activitiesSection.activity1") }}
              </option>
              <option class="option-css" :value="1" :selected="activities[1]">
                {{ $t("activitiesSection.activity2") }}
              </option>
              <option class="option-css" :value="2" :selected="activities[2]">
                {{ $t("activitiesSection.activity3") }}
              </option>
              <option :value="3" :selected="activities[3]">
                {{ $t("activitiesSection.activity4") }}
              </option>
            </select>
          </div>
        </div>

        <div class="selectedActivityTextAndPhoto-container">
          <div class="selectedAcivityDescription-container">
            <p class="selectedActivityDescriptionText" v-if="activities[0]">
              {{ $t("activitiesSection.selectedActivityText") }}
            </p>
            <p class="selectedActivityDescriptionText" v-if="activities[1]">
              {{ $t("activitiesSection.selectedEducation") }}
            </p>
            <p class="selectedActivityDescriptionText" v-if="activities[2]">
              {{ $t("activitiesSection.selectedFriendship") }}
            </p>
            <p class="selectedActivityDescriptionText" v-if="activities[3]">
              {{ $t("activitiesSection.selectedSupport") }}
            </p>
          </div>
          <img class="activitiesImg" v-if="activities[0]" :src="activitiesImg1" />
          <img class="activitiesImg" v-if="activities[1]" :src="activitiesImg2" />
          <img class="activitiesImg" v-if="activities[2]" :src="activitiesImg3" />
          <img class="activitiesImg" v-if="activities[3]" :src="activitiesImg4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import line from "@/assets/images/line.svg";
import activitiesImg1 from "@/assets/images/1. Home page/2. slika studentsko pretstavuvanje.jpg";
import activitiesImg2 from "@/assets/images/1. Home page/3. slika - obrazovanie i kultura.jpg";
import activitiesImg3 from "@/assets/images/1. Home page/4. slika - zabava i druzenje.jpg";
import activitiesImg4 from "@/assets/images/1. Home page/5. slika studentksa poddrshka.jpg";

export default {
  name: "NavBar",
  data() {
    return {
      line,
      activitiesImg1,
      activitiesImg2,
      activitiesImg3,
      activitiesImg4,
      activities: [true, false, false, false],
    };
  },

  methods: {
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
    switchActivity(activity) {
      for (var i = 0; i < 4; i++) {
        this.activities[i] = false;
      }
      this.activities[activity] = true;
    },
    goToFormSection() {
      this.$router.push('/form');
    },
  },
};
</script>

<style scoped>
.left-container-small-screens {
  display: none;
}

.main-container {
  width: 100%;
  height: 100%;
  padding-top: 128px;
  padding-bottom: 128px;
  background: #f9fafb;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 96px;
  display: flex;
  /* background-color: violet; */
}

.main-inner-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.container {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
}

.content {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  display: flex;
}

.headingAndSupportText-container {
  width: 70%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.heading {
  text-align: center;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  word-wrap: break-word;
}

.subheading {
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.activites-content-container {
  width: 85%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 90px;
  display: inline-flex;
  padding-top: 90px;
}

.left-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 330px;
  display: flex;
}

.upper-part-left-container {
  width: 95%;
  height: 250px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.listedActivities-container {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.activity {
  color: #646e82;
  font-size: 20px;
  font-weight: 400;
  text-align: start;
  line-height: 24px;
  word-wrap: break-word;
  cursor: pointer;
}

.selectedActivity {
  text-align: start;
  color: black;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  cursor: pointer;
  white-space: normal; /* Ensures text will wrap */
  overflow-wrap: break-word;
}

.line-container {
  position: relative;
  top: 20px;
}

.button-container {
  width: 100%;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.becomeAMemberButton {
  border: none;
  background: #fa7850;
  height: 60px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  cursor: pointer;
}

.becomeAMemberButton-inner {
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

.selectedActivityTextAndPhoto-container {
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  display: inline-flex;
  flex-grow: 1;
}

.activitiesImg {
    width: 100%;
    max-width: 600px;
    max-height: 600px;
  }

.selectedActivityDescription-container {
  width: 80%;
  height: 200px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.selectedActivityDescriptionText {
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: start;
  word-wrap: break-word;
}

.activitiesImg {
  border-radius: 24px;
}

@media only screen and (max-width: 1024px) {
  .main-inner-container {
    width: 100%;
  }
  .activites-content-container {
    width: 90%;
    gap: 80px;
  }

  .container {
    width: 100%;
  }

  .content {
    width: 100%;
  }

  .left-container {
    width: 30%;
  }

  .headingAndSupportText-container {
    width: 80%;
    padding-bottom: 10px;
  }

  .listedActivities-container {
    width: 80%;
  }

  .selectedActivityTextAndPhoto-container {
    width: 60%;
  }

  .selectedActivityTextAndPhoto-container img {
    width: 90%;
  }

  .selectedActivityDescription-container {
    width: 90%;
  }

  .selectedActivityDescriptionText {
    width: 90%;
  }

  .becomeAMemberButton {
    height: 45px;
    padding: 14px 24px 14px 24px;
  }
}

@media only screen and (max-width: 768px) {
  .main-container {
    gap: 0px;
    padding-top: 70px;
  }
  .left-container-small-screens {
    display: flex;
    width: 60%;
    justify-content: center;
  }

  .listedActivities-container {
    width: 100%;
  }

  .select-container {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../assets/images/dropdown_arrow_activities.png") no-repeat
      right 15px center;
    background-size: 20px 10px;
    /* Adjust 'right 0.5em' to move the arrow more or less to the left */

    background-color: #e6e7ec;
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    font-size: 15px;
    border: 0px;
    padding-right: 2em;
  }

  .select-container option {
    background-color: #e6e7ec;
    color: #101828;
  }

  .select-container option:checked {
    background-color: #c3c4c9;
    color: #101828;
  }

  .activites-content-container {
    width: 100%;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
  }

  .heading {
    font-size: 30px;
  }

  .subheading {
    font-size: 17px;
  }

  .selectedActivityTextAndPhoto-container {
    align-items: center;
    gap: 30px;
  }

  .selectedActivityDescription-container {
    width: 150px;
    font-size: 10px;
  }

  .selectedActivityDescriptionText {
    width: 100%;
    line-height: 24px;
    font-size: 17px;
  }

  .activitiesImg {
    width: 100%;
    /* max-width: 300px;
    max-height: 300px; */
  }

  .left-container {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .main-container {
    gap: 30px;
  }
  .left-container-small-screens {
    display: flex;
    width: 70%;
    justify-content: center;
  }

  .listedActivities-container {
    width: 100%;
  }

  .select-container {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../assets/images/dropdown_arrow_activities.png") no-repeat
      right 15px center;
    background-size: 20px 10px;
    /* Adjust 'right 0.5em' to move the arrow more or less to the left */

    background-color: #e6e7ec;
    width: 100%;
    padding: 15px;
    border-radius: 12px;
    font-size: 15px;
    border: 0px;
    padding-right: 2em;
  }

  .select-container option {
    background-color: #e6e7ec;
    color: #101828;
  }

  .select-container option:checked {
    background-color: #c3c4c9;
    color: #101828;
  }

  .heading {
    font-size: 32px;
  }

  .subheading {
    font-size: 18px;
  }

  .headingAndSupportText-container {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  .subheading {
    width: 85%;
  }

  .selectedActivityTextAndPhoto-container {
    align-items: center;
  }

  .selectedActivityDescription-container {
    width: 150px;
    font-size: 10px;
  }

  .selectedActivityDescriptionText {
    font-size: 15px;
    line-height: 24px;
  }

  .left-container {
    display: none;
  }
}
</style>
