<template>
    <body>
      <BlogTemplate />
    </body>
  </template>
  
  <script>
  import BlogTemplate from "@/components/BlogTemplate.vue";
  
  export default {
    name: "App",
    components: {
      BlogTemplate,
    },
  };
  </script>
  
  <style>
  body {
    margin: 0;
  }
  </style>