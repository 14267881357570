<template>
  <div class="navBar-container">
    <div class="innerNavBar-container">
      <div class="left-container">
        <router-link to="/">
          <div class="logoTitle-container">
            <img class="msosLogo" :src="msosLogo" />
          </div>
        </router-link>
        <div class="cards-container">
          <div class="dropdown">
            <div class="nav-link">
              <span>{{ $t("footer.aboutUs") }}</span>
              <img
                class="dropdown-arrow"
                :src="arrowImage"
                alt="Dropdown arrow"
              />
            </div>
            <div class="dropdown-content">
              <!-- <router-link to="/public-announcements"
                >Public announcements</router-link
              > -->
              <!-- <router-link to="/organisation-identity"
                >Organisation identity</router-link
              > -->
              <router-link to="/about-us">{{
                $t("footer.aboutUs")
              }}</router-link>
              <router-link to="/contact">{{
                $t("footer.contact")
              }}</router-link>
            </div>
          </div>
          <div class="dropdown">
            <div class="nav-link">
              <span>{{ $t("footer.forStudents") }}</span>
              <img
                class="dropdown-arrow"
                :src="arrowImage"
                alt="Dropdown arrow"
              />
            </div>
            <div class="dropdown-content">
              <router-link to="/brochure">{{
                $t("footer.brochure")
              }}</router-link>
              <router-link to="/blog">{{ $t("footer.blog") }}</router-link>
              <!-- <router-link to="/gallery">Gallery</router-link> -->
              <router-link to="/students">{{ $t("footer.faqs") }}</router-link>
            </div>
          </div>
          <div>
            <router-link to="/projects" class="nav-link">
              {{ $t("footer.projects") }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="right-container">
        <div class="buttons-container">
          <button
            style="display: none"
            type="button"
            class="becomeAPartnerButton"
          >
            <p class="becomeAPartnerButton-inner">
              {{ $t("navBarMSOS.becomeAPartner") }}
            </p>
          </button>
          <button type="button" class="donateButton">
            <p class="donateButton-inner">
              {{ $t("navBarMSOS.donate") }}
            </p>
          </button>
          <div class="globeDropdown-container">
            <div class="dropdown-languages">
              <img class="globe_icon" :src="globe" />
              <img class="dropdownButton" :src="dropdownButton" />
              <div class="dropdown-content">
                <div
                  class="dropdown-language-item"
                  @click.prevent="changeLocale('mk')"
                >
                  <img src="../assets/images/mkd_flag.svg" />
                  <a href="#">Macedonian</a>
                </div>
                <div
                  class="dropdown-language-item"
                  @click.prevent="changeLocale('si')"
                >
                  <img src="../assets/images/slo_flag.svg" />
                  <a href="#">Slovenian</a>
                </div>
                <div
                  class="dropdown-language-item last"
                  @click.prevent="changeLocale('en')"
                >
                  <img src="../assets/images/en_flag.svg" />
                  <a href="#">English</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <font-awesome-icon
          class="bars-icon"
          @click="showMobileNav = !showMobileNav"
          :icon="['fas', 'bars']"
        />
      </div>
    </div>
  </div>
  <div class="mobile-nav" v-if="showMobileNav">
    <div class="mobile-nav-items">
      <div class="mobile-nav-title">{{ $t("footer.home") }}</div>
      <!-- <router-link
        style="text-decoration: none"
        to="/public-announcements"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >Public announcements</router-link
      > -->
      <router-link
        style="text-decoration: none"
        to="/about-us"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >{{ $t("footer.aboutUs") }}</router-link
      >
      <!-- <router-link
        style="text-decoration: none"
        to="/organisation-identity"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >Organisation-identity</router-link
      > -->
      <router-link
        style="text-decoration: none"
        to="/contact"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >{{ $t("footer.contact") }}</router-link
      >
      <div class="mobile-nav-title">{{ $t("footer.forStudents") }}</div>
      <router-link
        style="text-decoration: none"
        to="/brochure"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >{{ $t("footer.brochure") }}</router-link
      >
      <router-link
        style="text-decoration: none"
        to="/blog"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >{{ $t("footer.blog") }}</router-link
      >
      <!-- <router-link
        style="text-decoration: none"
        to="/gallery"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >Gallery</router-link
      > -->
      <router-link
        style="text-decoration: none"
        to="/students"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >{{ $t("footer.faqs") }}</router-link
      >
      <router-link
        style="text-decoration: none"
        to="/projects"
        @click="showMobileNav = !showMobileNav"
        class="mobile-nav-item"
        >{{ $t("footer.projects") }}</router-link
      >
    </div>
    <div class="languages">
      <img
        @click.prevent="changeLocale('si')"
        src="../assets/images/slo_flag.svg"
      />
      <img
        @click.prevent="changeLocale('mk')"
        src="../assets/images/mkd_flag.svg"
      />
      <img
        @click.prevent="changeLocale('en')"
        src="../assets/images/en_flag.svg"
      />
    </div>
  </div>
</template>

<script>
import msosLogo from "@/assets/images/msos_logo_with_text.png";
import dropdownButton from "@/assets/images/chevron-down.svg";
import globe from "@/assets/images/globe_icon.svg";
import arrowImage from "@/assets/images/bottomArrowForDropdown.png";
import dropDownIcon from "@/assets/images/dropDownIcon.svg";

export default {
  name: "NavBar",
  data() {
    return {
      msosLogo,
      dropdownButton,
      globe,
      arrowImage,
      dropDownIcon,
      showMobileNav: false,
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style scoped>
.navBar-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerNavBar-container {
  width: 80%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 80px;
}

.left-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.logoTitle-container {
  display: flex;
  align-items: center;
  gap: 10.71px;
}

.msosLogo {
  /* width: 32px; */
  height: 32px;
  margin-top: 2%;
}

.title {
  font-size: 9.37px;
  line-height: 11.24px;
  color: #2d738c;
}

.bold-part {
  font-weight: bold;
}

.cards-container {
  display: flex;
  align-items: center;
  gap: 32px;
}

.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  font-size: 16px;
  color: #667085;
  cursor: pointer;
  position: relative;
}

.dropdown-arrow {
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  flex-direction: column;
  padding: 12px 16px;
  border-radius: 4px;
}

.dropdown-content a {
  color: #667085;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

.right-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.buttons-container {
  height: 44px;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  /* background-color: pink; */
}

.becomeAPartnerButton {
  height: 44px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #d0d5dd solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.becomeAPartnerButton-inner {
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.donateButton {
  width: 125px;
  height: 44px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #2d738c;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  border: 0.5px #2d738c solid;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* display: inline-flex; */
  display: none;
}

.donateButton-inner {
  width: 89px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.globeDropdown-container {
  display: inline-flex;
  /* background-color: peachpuff;  */
  justify-content: flex-start;
}

.dropdown-language-item {
  display: flex;
  width: 135px;
  padding: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(102, 112, 133, 0.32);
}

.dropdown-language-item.last {
  border-bottom: 0;
  padding-bottom: 0;
}

.dropdown-languages:hover .dropdown-content {
  display: flex;
  border-radius: 8px;
  background: var(--Snow-White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.languages {
  width: 30%;
  display: flex;
  justify-content: space-around;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-bottom: 2%;
}

.mobile-nav-items {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 2%;
  padding-top: 2%;
}

.mobile-nav-title {
  font-weight: bold;
  width: 100%;
  padding-top: 2%;
  border-bottom: 0.5px solid lightgray;
  padding-bottom: 2%;
}

.mobile-nav-item {
  width: 100%;
  border-bottom: 0.5px solid lightgray;
  padding-top: 1%;
  padding-bottom: 1%;
}

.mobile-nav-item:hover {
  background-color: lightgray;
}

.mobile-nav ul {
  list-style: none;
  /* width: 200px; */
  /* padding-left: 40px; */
}

.mobile-nav ul li {
  color: black;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}

.mobile-nav ul li:hover {
  color: #111;
}

.bars-icon {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .innerNavBar-container {
    width: 1024px;
    padding-right: 0px;
    padding-left: 0px;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 768px) {
  .cards-container {
    display: none;
  }

  .bars-icon {
    display: block;
  }

  .buttons-container {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
}
</style>
