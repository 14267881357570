<template>
  <body>
    <AboutUsHeader />
    <AboutUsAboutOurTeam />
    <OurTeam />
    <OurValues />
    <Form />
  </body>
</template>

<script>
import AboutUsHeader from "@/components/AboutUsHeader.vue";
import AboutUsAboutOurTeam from "@/components/AboutUsAboutOurTeam.vue";
import OurTeam from "@/components/OurTeam.vue";
import OurValues from "@/components/OurValues.vue";
import Form from "@/components/Form.vue";

export default {
  name: "AboutUs",
  components: {
    Form,
    AboutUsHeader,
    AboutUsAboutOurTeam,
    OurTeam,
    OurValues,
  },
  mounted() {
    //za MARIJA
    //koga se prenasocuva na /about-us, doagja tuka, i sekogas go skrola do gore, 
    //odnosno do prvata komponenta vnatre vo AboutUs.vue, a toa e AboutUsHeader
    //vnimatelno so ova
    window.scrollTo(0, 0);
  }
};
</script>

<style>
body {
  margin: 0;
}
</style>
