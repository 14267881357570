<template>
    <h1>404: Site not found</h1>
</template>
  
<script>

export default {
    name: "SiteNotFound",
    components: {},
};
</script>
  
<style scoped>
body {
    margin: 0;
}
</style>
  