<template>
  <div class="headerFAQsSection">
    <div class="header-container">
      <div class="header-content">
        <div class="headingAndSupportingText-container">
          <div class="miniHeading-heading-container">
            <p class="miniHeading">{{ $t("FAQsHeader.FAQs") }}</p>
            <p class="heading">{{ $t("FAQsHeader.askUsAnything") }}</p>
          </div>
          <p class="subtitle">
            {{ $t("FAQsHeader.subtitle") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsHeader",
  data() {
    return {};
  },
};
</script>

<style scoped>
.headerFAQsSection {
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}

.header-container {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: flex;
}

.header-content {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  display: flex;
}

.headingAndSupportingText-container {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.miniHeading-heading-container {
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7px;
  display: flex;
}

.miniHeading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  text-align: center;
  color: #fa7850;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  text-align: center;
  color: #101828;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  word-wrap: break-word;
  margin-bottom: 4pxpx;
}

.subtitle {
  width: 80%;
  text-align: center;
  color: #667085;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

@media only screen and (max-width: 768px) {
  .header-container {
    width: 100%;
  }

  .header-content {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
  }

  .headingAndSupportingText-container {
    gap: 0px;
  }

  .miniHeading-heading-container {
    gap: 0px;
  }

  .heading {
    font-size: 40px;
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .heading {
    font-size: 32px;
  }

  .subtitle {
    font-size: 16px;
  }
}
</style>
