<template>
  <div class="main-container">
    <div class="inner-container">
      <div class="faqs-container">
        <div class="question-container">
          <div class="questionAndCircle-container">
            <div class="questionAndAnswer">
              <div class="question">{{ $t("FAQsAllQuestions.question1") }}</div>
              <div class="answer" id="answer0">
                {{ $t("FAQsAllQuestions.answer1") }}
              </div>
            </div>
            <div
              class="circle"
              id="circle0"
              data-status="plus"
              @click="iconClicked0"
            >
              <img id="plus" :src="circlePlus" />
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="question-container">
          <div class="questionAndCircle-container">
            <div class="questionAndAnswer">
              <div class="question">{{ $t("FAQsAllQuestions.question2") }}</div>
              <div class="answer" id="answer1">
                {{ $t("FAQsAllQuestions.answer2") }}
              </div>
            </div>
            <div
              class="circle"
              id="circle1"
              data-status="plus"
              @click="iconClicked1"
            >
              <img :src="circlePlus" />
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="question-container">
          <div class="questionAndCircle-container">
            <div class="questionAndAnswer">
              <div class="question">{{ $t("FAQsAllQuestions.question3") }}</div>
              <div class="answer" id="answer2">
                {{ $t("FAQsAllQuestions.answer3") }}
              </div>
            </div>
            <div
              class="circle"
              id="circle2"
              data-status="plus"
              @click="iconClicked2"
            >
              <img :src="circlePlus" />
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="question-container">
          <div class="questionAndCircle-container">
            <div class="questionAndAnswer">
              <div class="question">{{ $t("FAQsAllQuestions.question4") }}</div>
              <div class="answer" id="answer3">
                {{ $t("FAQsAllQuestions.answer4") }}
              </div>
            </div>
            <div
              class="circle"
              id="circle3"
              data-status="plus"
              @click="iconClicked3"
            >
              <img :src="circlePlus" />
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="question-container">
          <div class="questionAndCircle-container">
            <div class="questionAndAnswer">
              <div class="question">{{ $t("FAQsAllQuestions.question5") }}</div>
              <div class="answer" id="answer4">
                {{ $t("FAQsAllQuestions.answer5") }}
              </div>
            </div>
            <div
              class="circle"
              id="circle4"
              data-status="plus"
              @click="iconClicked4"
            >
              <img :src="circlePlus" />
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="question-container">
          <div class="questionAndCircle-container">
            <div class="questionAndAnswer">
              <div class="question">{{ $t("FAQsAllQuestions.question6") }}</div>
              <div class="answer" id="answer5">
                {{ $t("FAQsAllQuestions.answer6") }}
              </div>
            </div>
            <div
              class="circle"
              id="circle5"
              data-status="plus"
              @click="iconClicked5"
            >
              <img :src="circlePlus" />
            </div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import circlePlus from "@/assets/images/plus-circle.svg";
import circleMinus from "@/assets/images/minus-circle.svg";

export default {
  name: "NavBar",
  data() {
    return {
      circlePlus,
      circleMinus,
    };
  },
  methods: {
    iconClicked0() {
      if (circlePlusCounter0) {
        changeImage(0);
        showAnswer(0);
        circlePlusCounter0 = 0;
      } else {
        changeImage(0);
        hideAnswer(0);
        circlePlusCounter0 = 1;
      }
    },
    iconClicked1() {
      if (circlePlusCounter1) {
        changeImage(1);
        showAnswer(1);
        circlePlusCounter1 = 0;
      } else {
        changeImage(1);
        hideAnswer(1);
        circlePlusCounter1 = 1;
      }
    },
    iconClicked2() {
      if (circlePlusCounter2) {
        changeImage(2);
        showAnswer(2);
        circlePlusCounter2 = 0;
      } else {
        changeImage(2);
        hideAnswer(2);
        circlePlusCounter2 = 1;
      }
    },
    iconClicked3() {
      if (circlePlusCounter3) {
        changeImage(3);
        showAnswer(3);
        circlePlusCounter3 = 0;
      } else {
        changeImage(3);
        hideAnswer(3);
        circlePlusCounter3 = 1;
      }
    },
    iconClicked4() {
      if (circlePlusCounter4) {
        showAnswer(4);
        changeImage(4);
        circlePlusCounter4 = 0;
      } else {
        hideAnswer(4);
        changeImage(4);
        circlePlusCounter4 = 1;
      }
    },
    iconClicked5() {
      if (circlePlusCounter5) {
        showAnswer(5);
        changeImage(5);
        circlePlusCounter5 = 0;
      } else {
        hideAnswer(5);
        changeImage(5);
        circlePlusCounter5 = 1;
      }
    },
  },
};
var circlePlusCounter0 = 1;
var circlePlusCounter1 = 1;
var circlePlusCounter2 = 1;
var circlePlusCounter3 = 1;
var circlePlusCounter4 = 1;
var circlePlusCounter5 = 1;

function changeImage(index) {
  var circleDiv = document.getElementById("circle" + index);
  var circleDataStatus = circleDiv.getAttribute("data-status");
  var circleImg = circleDiv.querySelector("img");

  if (circleDataStatus === "plus") {
    circleImg.src = circleMinus;
    circleDiv.setAttribute("data-status", "minus");
  } else {
    circleImg.src = circlePlus;
    circleDiv.setAttribute("data-status", "plus");
  }
}
function showAnswer(index) {
  styleAnswerShowHide(index, 250, 10, 1, 47);
}

function hideAnswer(index) {
  styleAnswerShowHide(index, 0, 0, 0, -47);
}

function styleAnswerShowHide(
  ansIndex,
  maxHeightPx,
  paddingTopPx,
  transformSize,
  innerContainerHeight
) {
  const answerElement = document.getElementById("answer" + ansIndex);
  answerElement.style.maxHeight = maxHeightPx + "px";
  answerElement.style.paddingTop = `${paddingTopPx}`;
  answerElement.style.transform = `scaleY(${transformSize})`;
  var innerContainer = document.querySelector(".inner-container");
  var prevHeight = innerContainer.clientHeight;
  var newHeight = prevHeight + innerContainerHeight;
  innerContainer.style.height = newHeight + "px";
}
</script>

<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 96px;
  background: #f9fafb;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  display: inline-flex;
}

.inner-container {
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  display: flex;
}

.faqs-container {
  /* background-color: aqua; */
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: flex;
  transition: all 0.3s;
}

.question-container {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  transition: all 0.3s;
}

.divider {
  align-self: stretch;
  height: 1px;
  background: #eaecf0;
}

.questionAndCircle-container {
  /* background-color: violet; */
  width: 100%;
  justify-content: space-between;
  /* align-items: flex-start; */
  gap: 24px;
  display: inline-flex;
}

.questionAndAnswer-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.question {
  align-self: stretch;
  color: #101828;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  word-wrap: break-word;
  text-align: start;
}

.answer {
  width: 100%;
  align-self: stretch;
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  text-align: start;
  max-height: 0px;
  overflow: hidden;
  /*  */
  transition: all 0.5s;
  transform-origin: top;
  transform: scaleY(0);
}

/* .question-container:hover .answer {
  max-height: 250px;
  padding-top: 10px;
  transform: scaleY(1);
} */
.circle {
  cursor: pointer;
  padding-top: 2px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.txt {
  color: #f9fafb;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .question {
    font-size: 16px;
  }

  .answer {
    font-size: 15px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 480px) {
  .answer {
    font-size: 14px;
    line-height: 20px;
  }

  .inner-container {
    width: 95%;
  }

  img {
    width: 20px;
    height: 20px;
  }
}
</style>
