<template>
  <div class="main-container">
    <div class="inner-container">
      <div class="left-container-msosTeamDescription">
        <div class="left-content-container">
          <div class="description-main-container">
            <div class="MSOSDate">
              {{ $t(`aboutUsTeamSection.items[${currentIndex}].date`) }}
            </div>
            <div class="text-container">
              <p class="msos-mini-header">
                {{
                  $t(`aboutUsTeamSection.items[${currentIndex}].miniHeading`)
                }}
              </p>
              <p class="msos-mini-description">
                {{
                  $t(
                    `aboutUsTeamSection.items[${currentIndex}].miniDescription`
                  )
                }}
              </p>
            </div>
          </div>
          <div class="mini-navigation-container">
            <button
              class="LRbutton"
              :src="buttonLR"
              @click="prevItem"
              :disabled="currentIndex === 0"
            >
              <img :src="leftArrow" style="width: 100%; margin-top: 5px" />
            </button>
            <button
              class="LRbutton"
              :src="buttonLR"
              @click="nextItem"
              :disabled="currentIndex === items.length - 1"
            >
              <img :src="rightArrow" style="width: 100%; margin-top: 5px" />
            </button>
          </div>
        </div>
      </div>
      <div class="right-container">
        <div class="img-container">
          <img class="msosTeamPhoto" :src="msosTeamPhoto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonLR from "@/assets/images/leftRight-elipse.png";
import msosTeamPhoto from "@/assets/images/2. About Us page/1. About Us page - Info sekcija_/6. msos-aktivnosti.png";
import rightArrow from "@/assets/images/chevron-right.png";
import leftArrow from "@/assets/images/chevron-left.png";

export default {
  name: "AboutUsAboutOurTeam",
  data() {
    return {
      buttonLR,
      msosTeamPhoto,
      currentIndex: 0,
      rightArrow,
      leftArrow,
    };
  },
  methods: {
    // Method to load the appropriate language file
    // loadItems() {
    //   if (this.language === "en") {
    //     this.items = en.items;
    //   } else if (this.language === "mk") {
    //     this.items = mk.items;
    //   }
    // },
    nextItem() {
      if (this.currentIndex < 7) {
        this.currentIndex++;
      }
    },

    prevItem() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
  },
  computed: {
    items() {
      return this.$t("aboutUsTeamSection.items");
    },
    // Get the current item based on the currentIndex
    count() {
      return this.$t("aboutUsTeamSection.items").length;
    },
  },
  // mounted() {
  //   this.loadItems();
  // }
};
</script>

<style scoped>
.LRbutton {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid 1px grey;
}

/* .description-main-container {
  display: flex;
  width: 100%;
} */
.main-container {
  width: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  height: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background: #f9fafb;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inner-container {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.left-container-msosTeamDescription {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* gap: 24px; */
}

.description-main-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.MSOSDate {
  text-align: start;
  align-self: stretch;
  color: #fa7850;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
  margin-bottom: 0px;
}

.text-container {
  width: 90%;
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.msos-mini-header {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: start;
  color: #101828;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  word-wrap: break-word;
}

.msos-mini-description {
  text-align: start;
  color: #667085;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}

.right-container {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.mini-navigation-container {
  gap: 24px;
  display: inline-flex;
}

.right-container img {
  width: 100%;
}

.img-shadow {
  width: 712px;
  height: 512px;
  background: #101828;
  box-shadow: 0px 32px 64px -12px rgba(15.96, 23.62, 40.04, 0.14);
}

.img-container {
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10vh;
  }

  .MSOSDate,
  .msos-mini-header,
  .msos-mini-description {
    text-align: center;
  }

  .left-content-container {
    align-items: center;
  }

  .left-container-msosTeamDescription {
    width: 80%;
  }

  .msosTeamPhoto {
    width: 100%;
    border-radius: 12px;
  }

  .img-container {
    justify-content: center;
    align-items: center;
  }

  .right-container {
    width: 55%;
  }

  .right-container img {
    width: 100%;
  }

  .description-main-container {
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .right-container {
    width: 65%;
  }

  .right-container img {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .msosTeamPhoto {
    width: 90%;
  }
  .text-container {
    width: 100%;
  }
  .left-container-msosTeamDescription {
    width: 80%;
    height: 100vh;
  }
  .msos-mini-header {
    font-size: 28px;
  }
  .msos-mini-description {
    overflow: auto;
  }
  .mini-navigation-container {
    margin-top: 30px;
  }

  .right-container {
    width: 75%;
  }

  .right-container img {
    width: 100%;
  }
}
</style>
