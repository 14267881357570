<template>
  <body>
    <HomeSection />
    <Partners />
    <HomePageTeamSection />
    <Activities />
    <LatestNews />
    <Form />
    <FAQsHomePage />
  </body>
</template>

<script>
import HomeSection from "@/components/HomeSection.vue";
import Partners from "@/components/Partners.vue";
import HomePageTeamSection from "@/components/HomePageTeamSection.vue";
import Activities from "@/components/Activities.vue";
import LatestNews from "@/components/LatestNews.vue";
import FAQsHomePage from "@/components/FAQsHomePage.vue";
import Form from "@/components/Form.vue";

export default {
  name: "HomePage",
  components: {
    HomeSection,
    Partners,
    HomePageTeamSection,
    Activities,
    LatestNews,
    FAQsHomePage,
    Form,
  },
};
</script>

<style scoped>
html {
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.inner-container {
  width: 1600px;
}

body {
  margin: 0;
}
</style>
