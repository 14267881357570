<template>
  <div class="main-footer-container">
    <div class="inner-footer-container">
      <div class="inner2-footer-container">
        <div class="levo-delce">
          <div class="logoIme">
            <img :src="imeLogo" />
          </div>
          <div>
            <p class="title">
              <span class="bold-part">
                <a
                  :href="googleMapsUrl"
                  target="_blank"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.address") }}
                </a>
              </span>
            </p>
            <p class="title">
              <span class="bold-part">
                <a
                  :href="'tel:' + phoneNumber"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.phoneNumber") }}
                </a>
              </span>
            </p>
            <p class="title">
              <span class="bold-part">
                <a
                  :href="'mailto:' + email"
                  target="_blank"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.emailAddress") }}
                </a>
              </span>
            </p>
            <div style="display: flex">
              <a :href="facebookLink" target="_blank">
                <img :src="facebookLogoWhite" style="margin-right: 10px" />
              </a>
              <a :href="instagramLink" target="_blank">
                <img :src="instagramLogoWhite" style="margin-right: 10px" />
              </a>
              <a :href="youtubeLink" target="_blank">
                <img :src="youtubeLogoWhite" style="margin-right: 10px" />
              </a>
            </div>
          </div>
        </div>
        <div class="desno-delce">
          <div class="klasa11">
            <div class="klasa12">
              {{ $t("footer.forStudents") }}
            </div>
            <div class="klasa13">
              <div class="klasa15">
                <a :href="'/blog'" style="color: white; text-decoration: none">
                  {{ $t("footer.blog") }}
                </a>
              </div>
              <div class="klasa15">
                <a
                  :href="'/students'"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.faqs") }}
                </a>
              </div>
              <div class="klasa15">
                <a
                  :href="'/brochure'"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.brochure") }}
                </a>
              </div>
            </div>
          </div>
          <div class="klasa16">
            <div class="klasa17">
              {{ $t("footer.projects") }}
            </div>
            <div class="klasa18">
              <div class="klasa19">
                <a
                  :href="'/underConstruction'"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.upcoming") }}
                </a>
              </div>
              <div class="klasa20">
                <a
                  :href="'/underConstruction'"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.previous") }}
                </a>
              </div>
            </div>
          </div>
          <div class="klasa21">
            <div class="klasa22">
              {{ $t("footer.organisation") }}
            </div>
            <div class="klasa23">
              <div class="klasa24">
                <a
                  :href="'/about-us'"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.aboutUs") }}
                </a>
              </div>
              <div class="klasa24">
                <a
                  :href="'/contact'"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footer.contact") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line-divider"></div>
    </div>
    <div class="allRightsReserved">
      <div style="position: relative">
        <div class="allRightsReserved2">
          {{ $t("footer.allRightsReserved") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import msosLogoWhite from "@/assets/images/msosLogoBlack.svg";
import facebookLogoWhite from "@/assets/images/facebook_icon.png";
import instagramLogoWhite from "@/assets/images/instagram_icon.png";
import youtubeLogoWhite from "@/assets/images/youtube_icon.png";
import imeLogo from "@/assets/images/frame_32.png";

export default {
  name: "FooterComponent",
  data() {
    return {
      msosLogoWhite,
      facebookLogoWhite,
      instagramLogoWhite,
      youtubeLogoWhite,
      imeLogo,
      address: "Masarykova cesta 24 1000 Ljubljana, Slovenia",
      email: "info@msosorg.com",
      facebookLink: "https://www.facebook.com/msos.org/",
      instagramLink: "https://www.instagram.com/msosorg_com/",
      youtubeLink: "https://www.youtube.com/@msosorg/",
    };
  },
  computed: {
    googleMapsUrl() {
      return `https://www.google.com/maps?q=${encodeURIComponent(
        this.address
      )}`;
    },
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  min-height: 34px;
  font-size: 13px;
  flex-direction: column;
  align-items: flex-start;
  line-height: 16.8px;
  word-wrap: break-word;
  padding-top: 5px;
  text-align: left;
  color: white;
}

.bold-part {
  font-weight: bold;
}

.main-footer-container {
  width: 100%;
  padding-bottom: 64px;
  padding-top: 70px;
  background: #2d738c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inner-footer-container {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rebeccapurple; */
}

.inner2-footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 95%;
  /* background-color: green; */
}

.levo-delce {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 480px;
  justify-content: center;
  /* background-color: yellow; */
}

.desno-delce {
  display: flex;
  gap: 35px;
  justify-content: center;
  max-width: 480px;
  /* background-color: cyan; */
}

.klasa5,
.klasa6,
.klasa7,
.title a {
  display: flex;
  align-items: center;
}

.klasa5 {
  gap: 16px;
}

.logoIme {
  width: 48px;
  height: 48px;
  position: relative;
}

.klasa6 {
  width: 48px;
  height: 48px;
  position: relative;
}

.klasa7 {
  width: 42.03px;
  height: 37.29px;
  position: absolute;
  left: 5.66px;
}

.klasa11,
.klasa16,
.klasa21 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
}

.klasa12,
.klasa17,
.klasa22 {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.klasa13,
.klasa18,
.klasa23 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
}

.klasa15,
.klasa19,
.klasa20,
.klasa24 {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.line-divider {
  width: 100%;
  height: 1px;
  border: none;
  background-color: white;
  margin-top: 20px;
}

.allRightsReserved {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.allRightsReserved2 {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

/* Media Queries */
@media (max-width: 1024px) {
  .inner-footer-container {
    padding: 0px;
  }

  .inner2-footer-container {
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .levo-delce {
    margin-left: 75px;
  }

  .levo-delce,
  .desno-delce {
    width: 100%;
  }

  .klasa5,
  .klasa6,
  .klasa7 {
    justify-content: center;
  }

  .title {
    text-align: center;
    align-items: center;
  }

  .klasa12,
  .klasa17,
  .klasa22 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 12px;
  }

  .klasa12,
  .klasa17,
  .klasa22 {
    font-size: 16px;
  }

  .klasa15,
  .klasa19,
  .klasa20,
  .klasa24 {
    font-size: 14px;
  }

  .allRightsReserved2 {
    font-size: 12px;
  }

  .levo-delce {
    margin-left: 0px;
  }

  .desno-delce {
    justify-content: start;
  }
  @media (max-width: 480px) {
    .levo-delce {
      width: 83%;
      justify-content: center;
    }
    .desno-delce {
      width: 83%;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  a {
    text-decoration: none;
  }
}
</style>
