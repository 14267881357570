<template>
  <div class="main-container">
    <div class="inner-container">
      <div class="heading">
        <p>{{ $t("partners.heading") }}</p>
      </div>
      <div class="partner-logos-container">
        <img class="companyLogo1" :src="companyLogo1" />
        <img class="companyLogo2" :src="companyLogo2" />
        <img class="companyLogo3" :src="companyLogo3" />
      </div>
    </div>
  </div>
</template>

<script>
import companyLogo1 from "@/assets/images/souLogo.png";
import companyLogo2 from "@/assets/images/kampus.png";
import companyLogo3 from "@/assets/images/capitalLogo.png";

export default {
  name: "NavBar",
  data() {
    return {
      companyLogo1,
      companyLogo2,
      companyLogo3,
    };
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  padding-top: 64px;
  padding-bottom: 88px;
  background: #f9fafb;
  justify-content: center;
  align-items: flex-start;
  display: inline-flex;
}

.inner-container {
  width: 90%;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 32px;
  display: flex;
}

.heading {
  align-self: stretch;
  text-align: center;
  color: #667085;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

.partner-logos-container {
  /* background-color: rebeccapurple; */
  margin-top: 32px;
  width: 80%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  display: flex;
}

.companyLogo1,
.companyLogo2,
.companyLogo3 {
  height: 70px;
}

@media only screen and (max-width: 1024px) {
  .partner-logos-container {
    justify-content: flex-start; /* Start logos from the left */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent logos from wrapping to the next line */
    gap: 16px; /* Optional: Adjust the gap between logos */
    height: 70px;
  }
}
@media only screen and (max-width: 768px) {
  .companyLogo1,
  .companyLogo2,
  .companyLogo3 {
    height: 60px;
    flex-shrink: 0; /* Prevent logos from shrinking when scrolling */
  }
}
@media only screen and (max-width: 480px) {
  .inner-container {
    width: 85%;
  }

  .companyLogo1,
  .companyLogo2,
  .companyLogo3 {
    height: 45px;
  }
}
</style>
